import React, { useState } from 'react'
import { Heading3 } from '@sb1/ffe-core-react'
import { SecondaryButton } from '@sb1/ffe-buttons-react'
import { FeedbackModal } from '../FeedbackModal/FeedbackModal'
import { Link, useLocation } from 'react-router-dom'

const promptMailTo = () => {
    window.location = 'mailto:apideveloper@sparebank1.no'
}

const FeedbackFooterContent = () => {
    const [openFeedbackModal, setOpenFeedbackModal] = useState(false)
    return (
        <React.Fragment>
            <Heading3>We appreciate your feedback</Heading3>
            <SecondaryButton className="footer-button" onClick={() => setOpenFeedbackModal(true)}>
                Feedback
            </SecondaryButton>
            {openFeedbackModal && <FeedbackModal onClose={() => setOpenFeedbackModal(false)} />}
        </React.Fragment>
    )
}

const ContactUsFooterContent = () => {
    return (
        <React.Fragment>
            <Heading3>Got more questions?</Heading3>
            <SecondaryButton className="footer-button" onClick={promptMailTo}>
                Contact us
            </SecondaryButton>
        </React.Fragment>
    )
}

const PSD2DeveloperFooterContent = () => {
    return (
        <React.Fragment>
            <Heading3>PSD2 Developer?</Heading3>
            <Link to={'/documentation/psd2'} className={'footer-button'}>
                <SecondaryButton>Click here</SecondaryButton>
            </Link>
        </React.Fragment>
    )
}

const GettingStartedFooterContent = () => {
    return (
        <React.Fragment>
            <Heading3>Having trouble?</Heading3>
            <Link to={'/documentation/FAQ'} className={'footer-button'}>
                <SecondaryButton>Check out our FAQ</SecondaryButton>
            </Link>
        </React.Fragment>
    )
}

const FooterContent = () => {
    const location = useLocation()
    switch (location.pathname) {
        case '/documentation/faq':
            return <ContactUsFooterContent />
        case '/documentation/gettingstarted-partner':
            return <PSD2DeveloperFooterContent />
        case '/documentation/gettingstarted':
            return <GettingStartedFooterContent />
        default:
            return <FeedbackFooterContent />
    }
}

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer-wrapper">
                <div className="bluewave-container-footer" />
                <div className="footer-content-container">
                    <FooterContent />
                </div>
            </footer>
        </React.Fragment>
    )
}

export default Footer
