import React, { useContext, useState } from 'react'
import { ClientContext } from '../../utils/ClientContext'
import { markets, marketsTextMapping } from '../../utils/textMaps'
import { Link } from 'react-router-dom'
import logoImg from '../../images/logo_hvit.png'
import { Tab, TabGroup } from '@sb1/ffe-tabs-react'
import { capitalize } from '../../utils/stringUtils'
import xcsrfToken from '../../utils/xcsrfToken'
import { SecondaryButton, ShortcutButton } from '@sb1/ffe-buttons-react'
import { CustomerNameHeader } from './CustomerNameHeader'
import './Header.less'

const customerName = SB1.loggedInCustomerFullName ? SB1.loggedInCustomerFullName : ''

const HomeHeader = () => {
    const { chosenMarket, setChosenMarket } = useContext(ClientContext)
    const [hamburgerClicked, setHamburgerClicked] = useState(false)

    return (
        <div
            className={
                chosenMarket === markets.PM
                    ? 'header-div-container-customer'
                    : 'header-div-container-partner'
            }
        >
            <div role="navigation" className={'desktop-header-parent'}>
                <div className="logo">
                    <Link to="/">
                        <img src={logoImg} alt="SpareBank 1 logo" width="140" height="38" />
                    </Link>
                </div>
                <TabGroup className="header-tab-group">
                    <Tab
                        selected={chosenMarket === markets.PM}
                        className="header-tab"
                        onClick={() => {
                            setChosenMarket(markets.PM)
                        }}
                    >
                        {capitalize(marketsTextMapping[markets.PM])}
                    </Tab>
                    <Tab
                        selected={chosenMarket === markets.BM}
                        className="header-tab"
                        onClick={() => {
                            setChosenMarket(markets.BM)
                        }}
                    >
                        {capitalize(marketsTextMapping[markets.BM])}
                    </Tab>
                </TabGroup>
                <div>
                    {!xcsrfToken() ? (
                        <Link to={'/login'}>
                            <ShortcutButton>Log in</ShortcutButton>
                        </Link>
                    ) : (
                        <CustomerNameHeader customerName={customerName} />
                    )}
                </div>
            </div>
            <div className={'mobile-header-component'}>
                <div className="hamburger-container">
                    <button
                        className="hamburger-anonymous-button"
                        onClick={() => setHamburgerClicked(!hamburgerClicked)}
                    >
                        <div
                            className={`hamburger-box ${hamburgerClicked && 'hamburger-change'}`}
                            id="hamburger-box"
                        >
                            <div id="bar1" className="bar" />
                            <div id="bar2" className="bar" />
                            <div id="bar3" className="bar" />
                        </div>
                    </button>
                </div>
                <Link to="/" className={'logo'}>
                    <img
                        className={
                            chosenMarket === markets.PM
                                ? 'header-logo-customer'
                                : 'header-logo-partner'
                        }
                        src={logoImg}
                        alt="SpareBank 1 logo"
                        width="140"
                        height="38"
                    />
                </Link>
            </div>
            <div className={'mobile-header-component'}>
                <nav
                    className={`hamburger-nav ${hamburgerClicked && 'hamburger-change'}`}
                    id="hamburger-nav"
                    background-color="fjell"
                >
                    <TabGroup className="header-tab-group">
                        <Tab
                            selected={chosenMarket === markets.PM}
                            className="header-tab"
                            onClick={() => {
                                setChosenMarket(markets.PM)
                            }}
                        >
                            {capitalize(marketsTextMapping[markets.PM])}
                        </Tab>
                        <Tab
                            selected={chosenMarket === markets.BM}
                            className="header-tab"
                            onClick={() => {
                                setChosenMarket(markets.BM)
                            }}
                        >
                            {capitalize(marketsTextMapping[markets.BM])}
                        </Tab>
                    </TabGroup>
                    {!xcsrfToken() ? (
                        <Link to="/login">
                            <SecondaryButton className="hamburger-login-button">
                                Log in
                            </SecondaryButton>
                        </Link>
                    ) : (
                        <CustomerNameHeader customerName={customerName} isMobile={true} />
                    )}
                </nav>
            </div>
        </div>
    )
}

export default HomeHeader
