function createEnumFromTextMapping(textMapping) {
    return Object.freeze(
        Object.fromEntries(Object.keys(textMapping).map((key) => [key.toUpperCase(), key])),
    )
}

export const marketsTextMapping = Object.freeze({
    bm: 'partner',
    pm: 'customer',
    common: 'common',
})

export const apiTypeTextMapping = Object.freeze({
    bm: 'Corporate',
    pm: 'Person',
    common: 'Common',
})

export const markets = createEnumFromTextMapping(marketsTextMapping)
