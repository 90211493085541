import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App.jsx'
import './images/favicon.ico'
import { ClientProvider } from './utils/ClientContext'
import './developer-portal-main.less'

const Root = () => (
    <ClientProvider>
        <App />
    </ClientProvider>
)
createRoot(document.getElementById('root')).render(<Root />)
