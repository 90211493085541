import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import HomeHeader from './HomeHeader'
import SimpleHeader from './SimpleHeader'

const homeHeaderPaths = [
    '/',
    '/showall',
    '/showall/',
    '/apis',
    '/apis/',
    '/apis/showall',
    '/apis/showall/',
]

export const Header = () => {
    const location = useLocation()
    const [headerType, setHeaderType] = useState('home')

    useEffect(() => {
        if (homeHeaderPaths.includes(location.pathname)) {
            setHeaderType('home')
        } else {
            setHeaderType('simple')
        }
    }, [location])

    if (headerType === 'home') {
        return <HomeHeader />
    }
    return <SimpleHeader />
}

export default Header
