export function capitalize(str) {
    if (str.length === 0) {
        return str
    }
    if (str.length === 1) {
        return str[0].toUpperCase()
    }
    return str[0].toUpperCase() + str.slice(1)
}

export const replaceWord = (word, replacementObject) => {
    if (replacementObject === undefined || replacementObject === null) return word

    return Object.keys(replacementObject).includes(word) ? replacementObject[word] : word
}

const sanitizeDelimiter = (myString) => {
    let newDelimiter = '<%>'
    while (myString.includes(newDelimiter)) newDelimiter = `${newDelimiter.slice(0, -1)}%>`

    return newDelimiter
}

const isNotSplittableString = (myString) => {
    return myString === '' || myString === undefined || myString === null
}

const genericSplit = (myString, substrings, buildString) => {
    if (isNotSplittableString(myString)) {
        return ['']
    }
    const delimiter = sanitizeDelimiter(myString)

    const temp = [...new Set(substrings)].flat().reduce((resultingString, currentSubstring) => {
        return resultingString.replaceAll(
            currentSubstring,
            buildString(currentSubstring, delimiter),
        )
    }, myString)

    return temp.split(delimiter).filter((str) => str !== '')
}

export const splitAround = (myString, ...substrings) => {
    const buildString = (currentSubstring, delimiter) =>
        `${delimiter}${currentSubstring}${delimiter}`

    return genericSplit(myString, substrings, buildString)
}

export const splitBefore = (myString, ...substrings) => {
    const buildString = (currentSubstring, delimiter) => `${delimiter}${currentSubstring}`

    return genericSplit(myString, substrings, buildString)
}

export const splitAfter = (myString, ...substrings) => {
    const buildString = (currentSubstring, delimiter) => `${currentSubstring}${delimiter}`

    return genericSplit(myString, substrings, buildString)
}
