import React, { useState } from 'react'
import PropTypes from 'prop-types'
import loginAppHost from '../../utils/loginUtil'
import { InlineExpandButton, ShortcutButton } from '@sb1/ffe-buttons-react'
import { Collapse } from '@sb1/ffe-collapse-react'
import './CustomerNameHeader.less'
import { Paragraph } from '@sb1/ffe-core-react'
const FID_SPAREBANK = 'fid-sparebank1'

export const CustomerNameHeader = (props) => {
    const [isExpanded, setIsExpanded] = useState(false)

    function logout() {
        window.location.replace(
            `${loginAppHost()}/auth/pages/logout.xhtml?app=kundefront-developer-portal&finInst=${FID_SPAREBANK}&goto=${
                window.location.origin
            }`,
        )
    }

    return (
        <>
            {props.customerName && !props.isMobile && (
                <div className="customer-name-header-container">
                    <InlineExpandButton
                        className="customer-name-header-expand"
                        isExpanded={isExpanded}
                        onClick={() => setIsExpanded(!isExpanded)}
                        aria-controls="inline-expand-customer-name-logout"
                        aria-expanded={isExpanded}
                    >
                        {props.customerName}
                    </InlineExpandButton>
                    <Collapse
                        className="customer-name-header-collapse"
                        id="inline-expand-customer-name-logout"
                        isOpen={isExpanded}
                    >
                        <ShortcutButton
                            className="header-logout-button"
                            onClick={logout}
                            tabIndex={!isExpanded ? -1 : 'null'}
                        >
                            Log out
                        </ShortcutButton>
                    </Collapse>
                </div>
            )}

            {props.customerName && props.isMobile && (
                <div className="customer-name-header-container-mobile">
                    <Paragraph className="customer-name-header-mobile-lead">
                        {props.customerName}
                    </Paragraph>
                    <ShortcutButton
                        className="header-logout-button"
                        onClick={logout}
                        tabIndex={!isExpanded ? -1 : 'null'}
                    >
                        Log out
                    </ShortcutButton>
                </div>
            )}
        </>
    )
}

CustomerNameHeader.propTypes = {
    customerName: PropTypes.string.isRequired,
    isMobile: PropTypes.bool,
}
