import React, { useContext } from 'react'
import { BackButton } from '@sb1/ffe-buttons-react'
import { Link, useHistory } from 'react-router-dom'
import logoImg from '../../images/logo_hvit.png'
import './SimpleHeader.less'
import { ClientContext } from '../../utils/ClientContext'
import { marketsTextMapping } from '../../utils/textMaps'

const SimpleHeader = () => {
    const history = useHistory()
    const { chosenMarket } = useContext(ClientContext)
    return (
        <div
            className={`simple-header-div 
        simple-header-div-${marketsTextMapping[chosenMarket]}`}
        >
            <div className="header-return-to-last-page">
                <BackButton
                    className="header-return-button"
                    onClick={() => {
                        if (history.length <= 2) {
                            history.push('/')
                        } else {
                            history.go(-1)
                        }
                    }}
                >
                    Go back
                </BackButton>
            </div>
            <Link to="/">
                <img src={logoImg} alt="SpareBank 1 logo" width="140" height="38" />
            </Link>
        </div>
    )
}

export default SimpleHeader
