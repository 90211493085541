import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@sb1/ffe-icons-react'
import closeIcon from '@sb1/ffe-icons/icons/open/300/md/close.svg'
import Modal from 'react-modal'
import { Heading3, LinkText, Paragraph } from '@sb1/ffe-core-react'
import { ActionButton, ButtonGroup, TertiaryButton } from '@sb1/ffe-buttons-react'
import { InputGroup, TextArea } from '@sb1/ffe-form-react'
import { ErrorMessage, SuccessMessage } from '@sb1/ffe-message-box-react'
import { postFeedback } from '../../adapters/webApi.adapter'

export const FeedbackModal = ({ onClose }) => {
    const [message, setMessage] = useState('')
    const [messageError, setMessageError] = useState('')
    const [fetching, setFetching] = useState(false)
    const [feedbackSent, setFeedbackSent] = useState(false)
    const [error, setError] = useState(false)

    const onKeyUp = (event) => {
        if (event.keyCode === 27) {
            onClose(event)
        }
    }

    const onSubmitFeedback = (event) => {
        event.preventDefault()
        if (message.trim() === '') {
            setMessageError('This field cannot be empty')
            return
        }
        setFetching(true)

        const onComplete = (response) => {
            const success = response === 'OK'
            setFeedbackSent(success)
            if (!success) {
                setError(true)
            }
            setFetching(false)
        }

        postFeedback(message, onComplete)
    }

    return (
        <Modal
            isOpen={true}
            appElement={document.getElementById('root')}
            contentLabel="Feedback Modal"
            className="modal"
            overlayClassName="modal-overlay"
            onRequestClose={onClose}
            shouldCloseOnOverlayClick={true}
            onKeyUp={(event) => onKeyUp(event)}
        >
            <LinkText underline={false} onClick={onClose} className="modal__close-link">
                <Icon fileUrl={closeIcon} ariaLabel="Lukk"></Icon>
            </LinkText>
            <div>
                {!feedbackSent && !error && (
                    <div>
                        <Heading3>We love to hear your feedback!</Heading3>
                        <Paragraph>
                            Your feedback will not be answered, but will be read by the developers
                            responsible for maintaining this website and used to improve the site.
                        </Paragraph>
                        <form onSubmit={onSubmitFeedback}>
                            <InputGroup fieldMessage={messageError ? messageError : null}>
                                <TextArea
                                    placeholder="Remember not to submit sensitive or personal information"
                                    rows={8}
                                    autoFocus={true}
                                    value={message}
                                    onChange={(event) => setMessage(event.target.value)}
                                />
                            </InputGroup>
                            <ButtonGroup thin={true} ariaLabel="Feedback and cancel buttons">
                                <ActionButton isLoading={fetching} onClick={onSubmitFeedback}>
                                    Send feedback
                                </ActionButton>
                                <TertiaryButton onClick={(event) => onClose(event)}>
                                    Cancel
                                </TertiaryButton>
                            </ButtonGroup>
                        </form>
                    </div>
                )}
                {feedbackSent && (
                    <SuccessMessage title="Feedback successfully sent">
                        <Paragraph>Thank you for your feedback!</Paragraph>
                    </SuccessMessage>
                )}
                {error && (
                    <ErrorMessage title="An error occured">
                        <Paragraph>Could not send feedback, try again later.</Paragraph>
                        <Paragraph>Ensure that you are logged in before trying again.</Paragraph>
                    </ErrorMessage>
                )}
            </div>
        </Modal>
    )
}

FeedbackModal.propTypes = {
    onClose: PropTypes.func,
}
