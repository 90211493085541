import React, { createContext, useState } from 'react'
import { markets } from './textMaps'

export const ClientContext = createContext(null)

export const ClientProvider = ({ children }) => {
    function setSelectedTabInSessionStorage(value) {
        sessionStorage.setItem('apiTabSelected', value)
    }

    function getSelectedTabFromSessionStorage() {
        return sessionStorage.getItem('apiTabSelected') ?? markets.PM
    }

    const [client, setClient] = useState(null)
    const [chosenMarket, setChosenMarketState] = useState(getSelectedTabFromSessionStorage)

    function setChosenMarket(value) {
        setSelectedTabInSessionStorage(value)
        setChosenMarketState(value)
    }

    return (
        <ClientContext.Provider value={{ client, setClient, chosenMarket, setChosenMarket }}>
            {children}
        </ClientContext.Provider>
    )
}
