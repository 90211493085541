import xcsrfToken from '../utils/xcsrfToken'

const noop = () => {}

const setHeaders = (options = { headers: {} }) => {
    return {
        ...options,
        headers: {
            ...options.headers,
            'X-CSRFToken': xcsrfToken(),
        },
    }
}

export const postFeedback = (payload, callback = noop) => {
    const postOptions = setHeaders({
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: payload,
    })

    fetch(`/api/userfeedback`, postOptions)
        .then((response) => response.text())
        .then(callback)
}

const getClientEndpoint = (clientId) => {
    return `/api/personal-client/clients/${clientId}`
}

export const getAllClientDetails = (clientList, callback = noop) => {
    Promise.allSettled(
        clientList.clients.map((clientObject) =>
            fetch(getClientEndpoint(clientObject.clientId), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': xcsrfToken(),
                },
            }).then((res) =>
                !res.ok ? Promise.reject('error fetching client details') : res.json(),
            ),
        ),
    ).then((results) => {
        const data = results.filter((res) => res.status === 'fulfilled').map((res) => res.value)
        callback(data)
    })
}
